require('./bootstrap');

const iso = require('isotope-layout');
const { data } = require('jquery');
const Swal = require('sweetalert2');
// var iso = require('isotope-layout/dist/isotope.pkgd.min');
require('isotope-fit-columns');
window.toastr = require('toastr')
const _ = require('lodash');
var org = '';

window.toastr.options = {
    positionClass: 'toast-top-center',
    progressBar: true,
    preventDuplicates: true,
    preventOpenDuplicates: true
};

function reInitIsotope(){
    var grid = new iso('.listing-container',{
        itemSelector: '.listing-item',
        transitionDuration: 0,
        masonry: {
            columnWidth: '.listing-item',
            isFitWidth: true
        }
    });
}

reInitIsotope();

// setTimeout(function(){startSearch();}, 200);
if (window.showDefaultEvents == 1 || window.location.search != '') {
    startSearch();
}

function startSearch(){
    var options = window.location.search.slice(1)
                      .split('&')
                      .reduce(function _reduce(/*Object*/ a, /*String*/ b)
                      {
                        b = b.split('=');
                         a[b[0]] = decodeURIComponent(b[1]);
                        return a

                      }, {});

    $.each(options, function (key,val) {
        if (val !== undefined) {
            let values = val.split(",");
            $.each(values, function (key2,value) {
                if (value !== 'undefined') {
                    if (key === 'search') {
                        $('input[name=search]').val(value);
                    } else if (key === 'org') {
                        org = value;
                    } else if ($('input[name='+key+']').hasClass('checkbox-check')) {
                        $('input[name='+key+']').prop("checked", true);
                    } else {
                        $('input[name='+key+'][value='+value+']').prop("checked", true);
                    }

                }
            });
        }
    });

    searchFunction(false,false,false);
}

$(document).on('click','[data-remove-id]', function () {
    var remove_id = $(this).attr('data-remove-id');
    var remove_value = $(this).attr('data-remove-value');
    searchFunction(false,remove_id,remove_value);
});

window.filter = {};

$('#filters').on("keyup", "input[name=search]", function (e) {
    if (e.which == 13) {
        searchFunction();
    }
});

$('#filters').on('change', 'input[type=checkbox]', function () {
    searchFunction();
});


window.limit = 100;
window.offset = 0;
window.wait = true;
function searchFunction(more = false, remove_id=false, remove_value=false)
{
    // more = true;
    if (more === false) {
        window.limit = 100;
        window.offset = 0;
    }
    // window.limit = $('.total span').html();

    if (more === true) {
        window.wait = true;
    }

    var data = [];
    var search = $('input[name=search]').val();

    if (remove_id == 'search') {
        $('input[name=search]').val('');
    }

    $('input[type=checkbox]').each(function () {
        if (this.checked && this.name !== "moove_gdpr_strict_cookies") {
            var value = $(this).val() == 'on' ? 1:$(this).val();
            var text = $(this).siblings('span').html() || '';
            if (text === '') {
                var text = $(this).parent().find('div').html();
            }

            if (remove_id == this.name && remove_value == value) {
                $(this).prop('checked',false);
                if (remove_id == 'search') {
                    $('input[name=search]').val('');
                }
            } else {
                data.push({'id':this.name,'value':value,'text':text});
            }
        }
    });
    var search = $('input[name=search]').val();
    if (search !== '') {
        data.push({'id':'search','value':search,'text':'Keresés: '+search});
    }
    if (org !== '') {
        data.push({'id':'org','value':org,'text':org});
    }
    let html = '';
    let url = {};

    $.each(data, function (key,val) {
        if (val.text != 'undefined') {
            if (val.id === 'org') {
                html += '<span class="tag"><span>Intézmény</span> <span class="remove" data-remove-id="'+val.id+'" data-remove-value="'+val.value+'"><i class="fas fa-times"></i></span></span>';
            } else {
                html += '<span class="tag"><span>'+val.text+'</span> <span class="remove" data-remove-id="'+val.id+'" data-remove-value="'+val.value+'"><i class="fas fa-times"></i></span></span>';
            }
            if (url[val.id] === undefined) {
                url[val.id] = new Array();
            }
            url[val.id].push(val.value);
        }
    });
    var link = '';
    var i = 0;
    $.each(url, function (key,val) {
        link += (i == 0) ? '?':'&';
        link += key+'=';
        i++;
        $.each(val, function (key2,param) {
            link += encodeURIComponent(param)+',';
        });
        if (link.charAt(link.length - 1) == ',') {
            link = link.substr(0, link.length - 1);
        }
    });
    if (link === '') {
        link = 'esemenyek';
    }

    window.history.replaceState(null, null, link);
    $('.filtered').html(html);

    window.filter = data;

    $.ajax({
        url: "/esemenyek/search",
        method: 'post',
        data: {
            _token: document.head.querySelector("[name=csrf-token]").content,
            search: window.filter,
            // limit:window.limit,
            limit: 100,
            offset:window.offset
        },
        success: function (result) {
            // if (more === true) {
            //     alert('a');
            //     $('.listing-container').append(result.html);
            //     window.wait = false;
            // } else {
            //     alert('b');
            //     $('.listing-container').html(result.html);
            // }
            $('.listing-container').html(result.html);
            $('.total span').html(result.total);
            $('#event_pager').html(result.pager);
            $('.pager_button').on('click', function() {
                window.offset = $(this).data('offset');
                searchFunction(true);
            });
            reInitIsotope();
            initFavorite();
            window.scrollTo(0, 0);
            $('.listing-container').css('height', 'auto');
        }
    });
}

// $(window).on("scroll", function() {
// var scrollHeight = $(document).height();
// var scrollPos = $(window).height() + $(window).scrollTop();

// if(((scrollHeight - 500) >= scrollPos) / scrollHeight == 0 && window.wait == false){
    // window.offset += 10;
    // search(true);
    // }
// });

$(document).click(function (event) {
    var target = $(event.target);

    $('.events-filter-group').each(function () {
        if ($(this).hasClass('show')) {
            $(this).removeClass('show');
        }
    });

    target.closest('.events-filter-group').addClass('show');

    $(".events-filter-group show").removeClass('show');
});

function initFavorite()
{
    $(".heart")
    .mouseenter(function () {
        var src = '/images/pictograms/heart_1.png';
        var checked = $(this).find('img').hasClass('checked');
        if (checked) {
            var src = '/images/pictograms/heart_0.png';
        }
        $(this).find('img').attr('src', src);
    })
    .mouseleave(function () {
        var src = '/images/pictograms/heart_0.png';
        var checked = $(this).find('img').hasClass('checked');
        if (checked) {
            var src = '/images/pictograms/heart_1.png';
        }
        $(this).find('img').attr('src', src);
    });
}

$(document).on('click', '.heart', function (e) {
    e.stopPropagation();
    var _this = $(this);
    var event_id = $(this).data('event-id');
    if (authCheck === 0) {
        Swal.fire({
            title: 'Figyelem!',
            icon: 'info',
            html:'A kedvencek gomb használatához bejelentkezés vagy regisztráció szükséges.',
            showCloseButton: true,
            showCancelButton: false,
            showDenyButton: true,
            denyButtonText: `Regisztráció`,
            confirmButtonText:'Bejelentkezés',
          })
          .then((result) => {
                if (result.isConfirmed) {
                    location.replace('/latogato/#/belepes');
                } else if (result.isDenied) {
                    location.replace('/latogato/#/regisztracio');
                }
            })
          return;
    } else {
        $.ajax({
            url: "/user/favorite",
            method: 'post',
            data: {
                _token: document.head.querySelector("[name=csrf-token]").content,
                event_id:event_id
            },
            success: function (result) {
                if (result.ok === 'success') {
                    window.toastr.success('Hozzáadva a kedvencekhez. A kedvenc események a "Profilom és eseményeim" menüpont alatt találhatóak.');
                    _this.find('img').addClass('checked');
                }
                if (result.ok === 'delete') {
                    window.toastr.success('Az esemény eltávolításra került a kedvenc események közül.');
                    _this.find('img').removeClass('checked');
                }
            }
        });
    }
});

initFavorite();

$('#favorite').click(function () {
    if (authCheck === 0) {
        Swal.fire({
            title: 'Figyelem!',
            icon: 'info',
            html:'A kedvencek gomb használatához bejelentkezés vagy regisztráció szükséges.',
            showCloseButton: true,
            showCancelButton: false,
            showDenyButton: true,
            denyButtonText: `Regisztráció`,
            confirmButtonText:'Bejelentkezés',
          })
          .then((result) => {
                if (result.isConfirmed) {
                    location.replace('/latogato/#/belepes');
                } else if (result.isDenied) {
                    location.replace('/latogato/#/regisztracio');
                }
            })
    }
});

$(window).scroll(function () {
    if ($(window).width() > 992) {
        if ($(this).scrollTop() > 250) {
            $('.listing-search-container').find('h5').hide();
            $('.listing-search-layer').addClass("fixed-top");
           // add padding top to show content behind navbar
            $('body').css('padding-top', $('.listing-search-layer').outerHeight() + 'px');
        } else {
             $('.listing-search-container').find('h5').show();
             $('.listing-search-layer').removeClass("fixed-top");
             // remove padding top from body
             $('body').css('padding-top', '0');
        }
    }
});
