window._ = require('lodash');

window.jQuery = $ = require('jquery');

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.bootstrap = require('bootstrap/dist/js/bootstrap');

$(function(){
    setTimeout(function(){
        $('.crypted').each(function(){
            $(this).text(atob($(this).text()));
            $(this).attr('href', atob($(this).data('payload')));
            $(this).removeClass('crypted');
        });
    },100);
})

$(function(){
    if(window.user === undefined && window.user !== null) return;

    var menu = [];
    if(window.user){
        if(window.user.isVisitor) {
            //menu.push({text: 'Profilom és Eseményeim', href: '/user/me'});
        }
        //menu.push({text:'Kijelentkezés', href:'/user/logout'});
    }else{
        //menu.push({text:'Belépés', href:'/latogato/#/belepes'});
    }
    for(var item of menu){
        $('#menu-header-menu-visitors').append(
            $('<li class="menu-item"></li>').append( $('<a />').attr(item).text(item.text).css({'font-size':'14px','text-decoration':'unset'}))


        );
    }
})
